import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import { config, ConfigPlugin } from "@/plugins/config";
import { Auth0Plugin } from "@/plugins/auth0";

const { domain, clientId } = config.auth0;

Vue.use(ConfigPlugin);
Vue.use(Auth0Plugin, {
    domain,
    clientId,
    onRedirectCallback: appState => {
        router.push(
            appState && appState.targetUrl
                ? appState.targetUrl
                : window.location.pathname
        );
    }
});

Vue.config.productionTip = false;

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount("#app");
