<template>
    <div class="home">
        <HelloWorld msg="Hellow World." />

        <div v-if="$feature('sanity')">
            <p>Feature Sanity: Yes</p>
            <SanityPosts />
        </div>
        <div v-else>
            <p>Feature Sanity: No</p>
        </div>

        <div v-if="!$auth.loading">
            <button v-if="!$auth.isAuthenticated" @click="login">Log in</button>
            <button v-if="$auth.isAuthenticated" @click="logout">
                Log out
            </button>
        </div>

        <p>Antall: {{ count }}</p>
        <button @click="add">+</button>
    </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from "@/components/HelloWorld.vue";
import SanityPosts from "@/components/SanityPosts.vue";
import { mapState, mapActions } from "vuex";

export default {
    name: "Home",

    components: {
        HelloWorld,
        SanityPosts
    },

    methods: {
        login() {
            this.$auth.loginWithRedirect();
        },

        logout() {
            this.$auth.logout({
                returnTo: window.location.origin
            });
        },

        ...mapActions({
            add: "increaseCounter"
        })
    },
    computed: mapState({
        count: state => state.CounterModule.counter
    })
};
</script>
