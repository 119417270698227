<template>
    <div>
        <div class="loading" v-if="loading">Loading...</div>

        <div v-if="error" class="error">
            {{ error }}
        </div>

        <div v-if="post" class="content">
            <h1>{{ post.title }}</h1>
            <img v-if="post.image" :src="imageUrlFor(post.image).width(480)" />

            <h6>By: {{ post.name }}</h6>
            <BlockContent :blocks="blocks" />
        </div>
    </div>
</template>

<script>
import BlockContent from "sanity-blocks-vue-component";
import sanityClient from "@/plugins/sanity";
import imageUrlBuilder from "@sanity/image-url";
// Vue.component("block-content", BlockContent);

const imageBuilder = imageUrlBuilder(sanityClient);

const query = `*[slug.current == $slug] {
  _id,
  title,
  slug,
  body,
 "image": mainImage{
  asset->{
  _id,
  url
}
},
"name":author->name,
"authorImage":author->image
}[0]
`;

export default {
    name: "BlogPost",
    components: { BlockContent },
    data() {
        return {
            loading: true,
            post: [],
            blocks: []
        };
    },
    created() {
        this.fetchData();
    },
    methods: {
        imageUrlFor(source) {
            return imageBuilder.image(source);
        },
        fetchData() {
            this.error = this.post = null;
            this.loading = true;

            sanityClient.fetch(query, { slug: this.$route.params.slug }).then(
                post => {
                    this.loading = false;
                    this.post = post;
                    this.blocks = post.body;
                },
                error => {
                    this.error = error;
                }
            );
        }
    }
};
</script>

<style scoped>
.content {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    max-width: 42em;
}
h1 {
    text-align: center;
}
h6 {
    color: #aaa;
    padding: 1em;
}
</style>
