const state = {
    counter: 10
};

const actions = {
    increaseCounter(context) {
        context.commit("increment");
    }
};

const mutations = {
    increment(state) {
        state.counter++;
    }
};

export default {
    state,
    actions,
    mutations
};
