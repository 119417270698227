// https://medium.com/dzangolab/vue-js-environment-variables-799fc080d736

const config = {
    apiBaseUrl: parse(
        process.env.VUE_APP_API_BASE_URL,
        "http://localhost:8080"
    ),

    auth0: {
        domain: process.env.VUE_APP_AUTH0_DOMAIN,
        clientId: process.env.VUE_APP_AUTH0_CLIENT_ID
    },

    features: {
        sanity: parse(process.env.VUE_APP_FEATURE_SANITY, false)
    }
};

function parse(value, fallback) {
    if (typeof value === "undefined") {
        return fallback;
    }

    switch (typeof fallback) {
        case "boolean":
            if (value.toLowerCase() === "true") {
                return true;
            } else {
                return false;
            }

        case "number":
            return JSON.parse(value);

        default:
            return value;
    }
}

function feature(name) {
    return config.features[name];
}

export { config };

export const ConfigPlugin = {
    install(Vue) {
        Vue.appConfig = config;
        Vue.feature = feature;

        Vue.prototype.$appConfig = config;
        Vue.prototype.$feature = feature;
    }
};
